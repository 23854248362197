@import "vars";

/** Reset start */

*{
  font-family: $generalFont;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;

  &:before, &:after {
    user-select: none;
  }

  &:focus,
  &:visited {
    outline: none;
  }

  &:focus-visible {
    outline: rgba(34, 197, 94, .2) solid 4px;
    transition: all ease-in 0.2s;

    &:not(a) {outline: none;}
  }

  @media (max-width: 1920px) {
    font-size: calc( 100vw / 1920 * 16 );
  }

  @media (max-width: 1680px) {
    font-size: calc( 100vw / 1680 * 16 );
  }

  @media (max-width: 1600px) {
    font-size: calc( 100vw / 1600 * 16 );
  }

  @media (max-width: 1540px) {
    font-size: calc( 100vw / 1540 * 16 );
  }

  @media (max-width: 1440px) {
    font-size: calc( 100vw / 1440 * 16 );
  }

  @media (max-width: 1366px) {
    font-size: calc( 100vw / 1366 * 16 );
  }

  @media (max-width: 1200px) {
    font-size: calc( 100vw / 1200 * 16 );
  }

  @media (max-width: 1180px) {
    font-size: 16px;
  }
}

body {
  margin: 0;
  padding: 0;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  background-color: $bodyBgColor
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
ul, ol, li {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

/** Reset end */
