@import "vars";
@import "mixins/typography-mixin";

/** Default */

body {
  background-color: $color-Dark;
  width: 100vw;
  position: relative;



}

.body-wrapper {
  &.bg-paint {
    &::before {
      content: "";
      background-image: image-set(
                      url("../assets/img/bg-main@2x.webp") type('image/webp') 2x,
                      url("../assets/img/bg-main@1x.webp") type('image/webp') 1x,
                      url("../assets/img/bg-main.png") type('image/png')
      );
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top -30em;
      aspect-ratio: 1/1;
      position: absolute;
      z-index: -1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: -webkit-fill-available;

      @media (max-width: 991.98px) {
        background-position: top;
        height: 100vh;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }

    }
  }
}

section {
  padding: em(64px) 0;

  @media (max-width: 991.98px) {
    padding: 36px 0;
  }
}

.logo-link {
  display: flex;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: rem(232px);
  height: rem(48px);

  .svg-wrapper {
    width: rem(48px);
    height: rem(48px);

    svg {
      width: 100%;
      height: 100%;
    }
  }

  span {
    margin-left: rem(10px);
    font-size: rem(24px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--Heading, $color-White-50);
  }
}

.header-wrap {
  width: rem(792px);
  margin: 0 auto;

  /* Medium devices (tablets, max 768px) */
  @media (max-width: 991.98px) {
    width: auto;
  }
}

.red {
  color: $color-Red;
}

.green {
  color: $color-Green-350;
}

.blue {
  background: linear-gradient(180deg, #30A9DD 0%, #208BD4 100%);
  color: #eee !important;

  &:hover {
    background: linear-gradient(180deg, #32B5ED 0%, #2196E6 100%) !important;
  }
}

.svg-fill-grey .svg-path{
  fill: $color-White-100;
}

.rotate {
  rotate: 180deg;
}

a.link {
  @include poppins-regular;
  font-size: 16px;
  line-height: normal;
  display: flex;
  align-items: center;
  color: $color-White-50;
  transition: all ease-in-out 0.2s;
  padding: rem(4px);
  border-radius: rem(8px);

  svg {
    margin-left: rem(6px);
  }

  &:hover, &:active {
    transition: all ease-in-out 0.1s;
    color: $activesColor;

    svg {
      .svg-path {
        transition: all ease-in-out 0.3s;
        fill: $hoverColor;
      }
    }
  }
}

/** Default end */
