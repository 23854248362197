@import "../vars";

@mixin poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

@mixin poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

@mixin poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

@mixin poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

@mixin inter-thin {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

@mixin inter-extralight {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

@mixin inter-light {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

@mixin inter-regular {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

@mixin inter-medium {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

@mixin inter-semibold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

@mixin inter-bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

@mixin inter-extrabold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

@mixin inter-black {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}



@mixin header-1 {
  @include poppins-semibold;
  font-size: $font-size-x;
  line-height: rem(68px);

  @media (max-width: 991.98px) {
    font-size: calc($font-size-x/2);
    line-height: 40px;
    letter-spacing: 0.5px;
  }

}

@mixin header-2 {
  @include poppins-semibold;
  font-size: $font-size-xl;
  line-height: 64px;
  letter-spacing: 0.5px;

  @media (max-width: 820px) {
    font-size: $font-size-xl;
    line-height: 64px;
  }

  @media (max-width: 600px) {
    font-size: 28px;
    line-height: normal;
  }

}

@mixin header-3 {
  @include poppins-semibold;
  font-size: $font-size-l;
  line-height: 40px;
  letter-spacing: 0.5px;

  @media (max-width: 991.98px) {
    line-height: 29px;
    font-size: calc($font-size-xl/2);
  }

}

@mixin header-4 {
  @include inter-medium;
  font-size: $font-size-m;
  line-height: normal;

  @media (max-width: 991.98px){
    font-size: $font-size-r;
  }

}

@mixin header-6 {
  @include inter-semibold;
  font-size: $font-size-s;
  line-height: normal;
}
