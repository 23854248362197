@import "vars";

/** Grid start */

.container {
  max-width: $mainContainer;
  width: $mainContainer;
  margin: 0 auto;


  @media (max-width: 1200px) {
    width: 1100px;
    margin: 0 auto;
  }

  @media (max-width: 992px) {
    width: unset;
    margin: 0 16px;
  }
};

.container:not(.main-header .container):not(.header-mobile .container) {
  display: flex;
  flex-direction: column;
};

.fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
}

/** Grid end */
