@import "vars";

/** media */

/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
  /* CSS правила для экранов шириной меньше 576px */
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  /* CSS правила для экранов от 576px до 767px */
}

/* Medium devices (tablets, max 768px) */
@media (max-width: 991.98px) {
  * {
    font-size:  16px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* CSS правила для экранов от 768px до 991px */
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  /* CSS правила для экранов от 992px до 1199px */
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1599.98px) {
  /* CSS правила для экранов от 1200px до 1599px */
}

/* Ultra large devices (very large desktops, 1600px and up) */
@media (min-width: 1600px) {
  /* CSS правила для экранов шириной больше 1600px */
}

/* Extremely large devices (ultra wide screens, 1920px and up) */
@media (min-width: 1920px) {
  /* CSS правила для экранов шириной больше 1920px */
}

/** media end*/
