@import "../vars";
@import "../mixins/typography-mixin";

/** Precision */

section.precision {
  position: relative;
  border-radius: em(16px);

  .container {
    position: relative;
    padding: 2px;
    border-radius: 13px;
    //border: 2px solid transparent;
  }

  .wrap {
    position: relative;
    padding: em(64px) em(48px);
    border-radius: 12px;
    z-index: 4;

    //border: 2px solid rgba(255, 255, 255, 0);

    background: rgba(#191C20, 0.80);

    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 570px;
      height: 430px;
      background-image: image-set(
              url("/assets/img/bg_precision_@1x.png") type('image/png') 1x,
              url("/assets/img/bg_precision_@1x.webp") type('image/webp') 1x,
              url("/assets/img/bg_precision_@1x.avif") type('image/avif') 1x,
              url("/assets/img/bg_precision_@2x.png") type('image/png') 2x,
              url("/assets/img/bg_precision_@2x.webp") type('image/webp') 2x,
              url("/assets/img/bg_precision_@2x.avif") type('image/avif') 2x
      );
      z-index: 3;
      background-repeat: no-repeat;
      background-position: right 0;
      background-size: contain;

      @media (max-width: 991.98px) {
        background-position: right bottom;
        width: 100%;
      }
    }

    @media (max-width: 991.98px) {
      height: 33.25em;
      padding: em(22px) em(32px);
    }

    @media (max-width: 821px) {
      height: 38em;
    }

    @media (max-width: 600px) {
      height: 33.25em;
    }

  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    @media (max-width: 991.98px) {
      text-align: center;
    }

    .header {

      @media (max-width: 991.98px) {
        padding: 0 16px;
      }
      @media (max-width: 821px) {
        margin: auto;
      }

    }

    .description {
      margin-top: em(16px);
      width: 40%;
      text-align: inherit;

      @media (max-width: 991.98px) {
        width: 100%;
      }
    }
  }

  .btn {
    margin-top: em(140px);
    svg {
      margin-left: em(8px);
    }

    @media (max-width: 991.98px) {
      margin: em(36px) auto 0;
    }
  }
}

/** Precision end */
