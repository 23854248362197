/** Lighting */

*:before,
*:after {
  box-sizing: border-box;
  position: relative;
}

.lighting {
  --x-px: calc(var(--x) * 1px);
  --y-px: calc(var(--y) * 1px);
  overflow: hidden;
  background-color: rgba(white, 0.1);

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: radial-gradient(800px circle at var(--x-px) var(--y-px), #22c55e70, transparent 70%);

  }

  &:before {
    z-index: 1;
  }

  &:after {
    opacity: 0;
    z-index: 2;
    transition: opacity 0.4s ease;
  }

  &:hover:after {
    opacity: 1;
  }
}

.lighting-container {
  z-index: 1;
}

.lighting-card {
  padding: 2px;
  border-radius: 16px;
  background-color: rgba(white, 0.1);
  position: relative;
}

.lighting-card-wrap {
  position: relative;
  z-index: 4;
  background-color: rgba(#191C20, 0.80);
  border-radius: 14px;
  backdrop-filter: blur(12px);
}

/** Lighting end */
