@import "reset";
@import "typography";
@import "elements/butons";
@import "elements/input";
@import "grid";
@import "default";

@import "elements/lighting";

@import "elements/list";

@import "components/precision";

@import "media";

.blockchain-list .slick-list .slick-track {
  padding: 4px 0;
}