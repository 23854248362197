@import "../vars";
@import "../mixins/typography-mixin";

/** Buttons start */

.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all ease-out 0.2s;

  @include poppins-regular;
  z-index: 4;
  width: max-content;
  position: relative;

  span {
    margin-right: 8px;
    font-size: inherit;
    margin-top: 0 !important;
  }

  &.btn-l {
    padding: rem(16px) rem(32px);
    font-size: 20px;

    @media (max-width: 991.98px) {
      padding: rem(12px) rem(24px);
    }
  }
  &.btn-m {
    padding: rem(12px) rem(24px);
    font-size: 16px;

    @media (max-width: 991.98px) {
      padding: rem(8px) rem(16px);
    }
  }
  &.btn-s {
    padding: rem(8px) rem(16px);
    font-size: 14px;
  }

  &.btn-l,
  &.btn-m,
  {
    @media (max-width: 991.98px) {
      font-size: 16px;
    }
  }

  &:hover:not(.disabled):not(.btn-text):not(.btn-icon):not(.btn-extra) {
    background-color: rgba(255, 255, 255, 0.10);
  }


  &:hover {
    cursor: pointer;
    transition: all ease-out 0.2s;

    &.btn-text {
      .svg-path {
        stroke: $hoverColor;
      }
    }
  }

  &:active, &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: rgba(34, 197, 94, .2) solid 4px;
    transition: all ease-in 0.2s;
    //border-color: transparent;
  }


  &:disabled, &.disabled {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    .arrow-container {
      opacity: 0;
    }

  }
}

.btn-text {
  font-size: 16px;
  color: $color-White-50;
  border-radius: 32px;

  padding: rem(9px) rem(32px);
  text-wrap: nowrap;

  &:hover {
    color: $activesColor;
  }

  &:hover,
  &:active,
  &:focus {
    transition: all ease-in-out 0.2s;
  }

  @media (max-width: 991.98px) {
    margin-left: em(-32px);
  }
}

.btn-icon {
  background-color: rgba(255, 255, 255, 0.10);
  border-radius: 50%;
  //width: 32px;
  height: 32px;
  padding: 4px;
  border: none;

  &:hover {
    background-color: rgba(255, 255, 255, 0.20);
  }

  &:disabled {
    background-color: rgba(255, 255, 255, 0.10);
  }


}

.btn-outlined, button.btn-outlined {
  border: 1px solid $color-White-300;
  border-radius: 50px;
  color: $color-White-50;
  background-color: transparent;
  font-size: rem(20px);
  font-style: normal;
  text-wrap: nowrap;
}

.btn-floating {

}

button.btn-extra, .btn-extra {
  width: auto;
  min-width: -moz-available;
  background-color: $color-White-100;
  color: $color-Dark;
  border: none;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 991.98px) {
    padding: 12px !important;
  }

  svg {
    margin-right: em(8px);
  }

  &:hover {
    background-color: $color-White-50;
  }

}

.btn-mobile-menu {
  background-color: transparent;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  padding: 10px;
}

.btn.blue {
  align-items: center;

  span {
    margin: 0;
    color: $color-White-50;
  }
}

/** Buttons end */
