@import "../vars";
@import "../mixins/typography-mixin";

/** List */

section.slider-list {
  h2.header {
    margin-bottom: rem(44px);
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(356px, 1fr));
    gap: $font-size-l;

    @media (max-width: 1366px) {
      grid-template-columns: repeat(3, minmax(300px, 1fr));
    }

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, minmax(361px, 1fr));
    }

    @media (max-width: 992px) {
      grid-template-columns: repeat(auto-fit, minmax(361px, 1fr));
      gap: 16px;
    }

  }

}

.card {
  border-radius: $border-radius;
  backdrop-filter: $blur;
  overflow: hidden;

  .head {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    height: em(200px);
    overflow: hidden;
  }

  .header {
    color: $color-White-50;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    text-align: start;
  }

  p, span {
    @include inter-regular;
    color: $color-White-100;
    font-size: $font-size-thin;
  }

  span {
    margin-top: $font-size-s;
    display: flex;
  }



  .content {
    padding: em(24px);
    position: relative;
  }

  .btn, .content-body {
    margin-top: em(20px);
  }

  .content-body p {
    line-height: 20px;
  }
}

.list-wrapper {
  @media (min-width: 1366px) {
    &.wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 -50px 0;
    }

    .list {
      margin: 0 16px;
      grid-template-columns: repeat(3, minmax(356px, 1fr));
    }
  }

  @media (max-width: 1366px) {
    & > .btn {
      display: none;
    }
    .list {
      margin: 0;
    }
  }


}

/** List end */
