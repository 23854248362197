@import "mixins/@mixin";

$color-White-50: #fafafa;
$color-White-100: #B4BAC1;
$color-White-200: #8E8F91;
$color-White-300: #52525B;

$color-Grey-50: rgba(255, 255, 255, 0.20);
$color-Grey-100: rgba(255, 255, 255, 0.10);
$color-Grey-200: rgba(54, 53, 53, 0.40);
$color-Grey-300: rgba(54, 53, 53, 0.30);

$color-Green-300: #4ADE80;
$color-Green-350: #22C55E;

$color-Red: #EF4444;
$color-Cyan: #8AFCFC;
$color-Yellow: #FFEA2C;
$color-Orange: #F5D78A;
$color-Blue: #77C6FF;
$color-Slate: #BED2F1;
$color-Purple: #B673F7;
$color-Green: #A5FFB3;
$color-Rose: #FFA6BE;
$color-Sky: #8ED7F6;
$color-Violet: #7E6AFC;
$color-Dark: #0C1117;

$gradient-Green: linear-gradient(180deg, #16A368 0%, #052E1D 100%);


$bodyBgColor: $color-Dark;
$activesColor: $color-Green-300;
$accentColor: $color-Green-300;
$hoverColor: $color-Green-300;

$blur: blur(12px);


$generalFont: 'Poppins', system-ui, apple-system, BlinkMacSystemFont, "Segoe UI","Helvetica Neue", Arial, sans-serif;

$mainContainer: 1260px;
$main-header-height: em(100px);

$border-radius: 16px;

$font-size-ultra-thin: 12px;
$font-size-thin: 14px;
$font-size-s: 16px;
$font-size-r: 20px;
$font-size-m: 24px;
$font-size-l: 32px;
$font-size-xl: 48px;
$font-size-x: 64px;