@import "vars";

/** input */
textarea, input {
  box-sizing: border-box;
}

.group-control {
  position: relative;

  label {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }

  .error {
    position: absolute;
    top: 100%;
    color: $color-Red;
    margin-top: 4px;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
  }
}

.control {
  @include control;
  margin-top: 8px;

  ::placeholder {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #FFFFFF33;
    transition: color .2s ease-in-out;
  }

  &:hover, &:focus-visible {
    border-color: #FFFFFF33;
    transition: color .2s ease-in-out;
  }
  &:focus {
    &::placeholder {
      color: #FFFFFF00;
      transition: color .2s ease-in-out;
    }
  }
  &:disabled {
    background: rgba(54, 53, 53, 0.30) !important;
  }
}

/** input end */
