@import "vars";
@import "./mixins/typography-mixin";
@import "mixins/@mixin";

/** Fonts */
// font: font-style font-variant font-weight font-size/line-height font-family|caption|icon|menu|message-box|small-caption|status-bar|initial|inherit;

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

/** Fonts end */


/** Typography */


h1.header {
  @include header-1;
}

h2.header {
  @include header-2;
}

h3.header {
  @include header-3;
}

h4.header, .header-4 {
  @include header-4;
}

h6.header, .header-6 {
  @include header-6;
}

.subtitle {
  @include inter-light;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 991.98px) {
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: 0.08px;
  }

}

.description {
  @include inter-light;
  font-size: 20px;
  line-height: 28px;
  color: $color-White-100;
  text-align: center;

  @media (max-width: 820px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.text {
  @include inter-regular;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0.08px;
  text-align: center;
}

h1.header, h2.header, h3.header,
h4.header, h6.header, .subtitle {
  text-align: center;
  color: $color-White-50;
}

/** Typography end */


